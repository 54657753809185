<template lang="pug">
.Services
  .headerFake
  .d-flex.align-items-center.justify-content-between(@click="goBack").goBack
    img(src="@/assets/mobileFSFB/icn_backicn_back.png" alt="icn_backicn_back").icn_backicn_back.ml-3
  .backgroundBuild
  .containerBox(v-if="sede")
    .caveFake
    .px-4.pt-4(style="margin-top: -16px;")
      small.SedeTitle.smallest: strong Servicios
      h2.SedeTitle: strong {{sede.name}}
      p.descP.mb-3 ¡A tu disposición! Conoce a cuáles servicios puedes acceder en esta sede.
      .pb-5
        button(
          v-for="item in Object.keys(areaData).sort()"
          @click="continueToLogin(areaData[item])"
        ).button-without-styles.arrowButtonServices.d-flex.justify-content-between.align-items-center.mb-4
          .d-flex.align-items-center
            img(:src="areaData[item].icon" )
            span.buttonText.ml-3 {{areaData[item].label}}
          svg(width='24' height='24' viewbox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg')
            path(d='M4 12C4 10.4178 4.46921 8.87105 5.34826 7.55546C6.22731 6.23987 7.47673 5.21449 8.93853 4.60899C10.4003 4.00349 12.0089 3.84506 13.5607 4.15374C15.1125 4.46242 16.538 5.22435 17.6568 6.34317C18.7757 7.46199 19.5376 8.88744 19.8463 10.4393C20.155 11.9911 19.9965 13.5997 19.391 15.0615C18.7855 16.5233 17.7601 17.7727 16.4446 18.6518C15.129 19.5308 13.5823 20 12 20C9.87828 20 7.84344 19.1571 6.34316 17.6569C4.84287 16.1566 4 14.1217 4 12Z' stroke='#788591' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round')
            path(d='M13.5991 11.9999L11.4618 9.86266' stroke='#788591' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round')
            path(d='M11.4618 14.1373L13.5991 12.0001' stroke='#788591' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round')
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "Services",

  computed: {
    ...mapState({
      sites: state => state.mobileFSFB.sites
    }),

    areas() {
      try {
        return this.sites[0].customAreas;
      } catch (error) {
        return "";
      }
    },

    sede() {
      try {
        let sede = this.$route.params.code;
        return this.sites.find(s => s.code == sede);
      } catch (error) {
        return {};
      }
    },

    areaData() {
      return Object.keys(this.areas)
        .map(item => {
          return { code: item, ...this.areas[item] };
        })
        .reduce(function(objectAreas, curValue) {
          objectAreas[curValue.label] = curValue;
          return objectAreas;
        }, {});
    }
  },

  methods: {
    ...mapActions({
      getSites: "mobileFSFB/getSites"
    }),

    goBack() {
      this.$emit("changeMode", "SelectZone");
    },

    continueToLogin(area) {
      this.$emit("changeMode", "Login");
      this.$router.replace({ query: { area: area.code } });
    }
  }
};
</script>

<style lang="scss" scoped>
.Services {
  display: grid;
  grid-template-rows: 300px minmax(448px, 2fr);
  height: 100%;
  overflow: auto;
  background: white;
}

.backgroundBuild {
  width: 100%;
  height: 452.8px;
  position: fixed;
  max-width: 450px;
  width: 100%;
  left: 50%;
  transform: translate(-50%);
  grid-row: 1;
  background-attachment: fixed;
  background-position: initial;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(../../../assets/mobileFSFB/backgroundBuild.png);
}

::-webkit-scrollbar {
  display: none;
}

.containerBox {
  width: 100%;
  height: fit-content;
  // max-height: 100%;
  // overflow-y: auto;
  position: relative;
  grid-row: 2;
  background: var(--color-white);
  border-radius: 24px 24px 0px 0px;
}

.goBack {
  position: absolute;
  top: 21px;
  z-index: 6;
}

.SedeTitle {
  font-size: 30px;
  line-height: 35px;
  color: #001698;
}

.smallest {
  font-size: 15px;
  line-height: 23px;
}

.descP {
  font-size: 17px;
  line-height: 22px;
  color: #788591;
}

.arrowButtonServices {
  width: 100%;
  min-height: 80px;
  text-align: start;
  padding: 1rem;
  background: #ffffff;
  box-shadow: 0px 1px 2px rgba(21, 21, 21, 0.02),
    0px 4px 8px rgba(21, 21, 21, 0.08);
  border-radius: 20px;
}

.buttonText {
  font-size: 22px;
  line-height: 26px;
  color: #788591;
}

.headerFake {
  position: fixed;
  height: 120px;
  max-width: 450px;
  width: 100%;
  left: 50%;
  transform: translate(-50%);
  right: 0;
  background-attachment: fixed;
  background-position: initial;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(../../../assets/mobileFSFB/backgroundBuild.png);
  z-index: 5;
}

.caveFake {
  position: sticky;
  top: 105px;
  width: 100%;
  height: 35px;
  border-radius: 25px;
  z-index: 6;
  border: 16px solid white;
  border-bottom: none;
}

@media screen and (max-width: 376px) {
  .backgroundBuild {
    background-size: contain;
  }
}
</style>
